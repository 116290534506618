<template>
  <b-row>
    <b-col
      cols="12"
      md="6"
    >
      <b-card title="Info">
        <b-card-text>
          <p> Email: <code>{{ order.email }}</code> </p>
          <p> 创建时间: <code>{{ convertTimestamp(order.iat) }}</code> </p>
          <p> 过期时间: <code>{{ convertTimestamp(order.exp) }}</code> </p>
        </b-card-text>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-card title="Name/Password">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="First Name"
                  label-for="h-first-name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-first-name"
                    v-model="userName.givenName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="LastName"
                  label-for="h-email"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="last-name"
                    v-model="userName.familyName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="FullName"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-number"
                    v-model="userName.fullName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Password"
                  label-for="h-password"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-password"
                    type="password"
                    placeholder="Password"
                  />
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col offset-md="4">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="update"
                >
                  修改名字
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="updatePassword"
                >
                  修改密码
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useHttp from '@/http/useHttp'
import { convertTimestamp } from '@/utils/format-date'
import { notify, throwError, throwRes } from '@/utils/utils'
import {
  BRow, BCol, BCard, BCardText, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: 0,
      order: {},
      arg: {},
      userName: {},
      password: '',
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getData()
  },
  methods: {
    async getData() {
      await useHttp.getMailXOrderInfo(this.id, this.arg).then(res => {
        // this.data = res.data.data
        const { data } = res.data
        this.order = data.Order
        this.userName = data.UserName
      }).catch(error => {
        notify('Error', error, 'danger')
      })
    },
    update() {
      useHttp.putMailXOrderUserName(this.id, {
        FirstName: this.userName.givenName,
        LastName: this.userName.givenName,
        FullName: this.userName.fullName,
      }).then(res => {
        throwRes(res)
        notify('OK', '修改成功')
      }).catch(error => {
        throwError(error)
        notify('Fail', '修改失败', 'danger')
      })
    },
    updatePassword() {
      useHttp.putMailXOrderPassword(this.id, {
        Password: this.password,
      }).then(res => {
        throwRes(res)
        notify('OK', '修改成功')
      }).catch(error => {
        throwError(error)
        notify('Fail', '修改失败', 'danger')
      })
    },
    convertTimestamp,
  },
}
</script>

<style>

</style>
