import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

export function getCookie(cname) {
  const name = `${cname}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    const c = ca[i].trim()
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
  }
  return ''
}

export function getMonthInfo() {
  return [
    {
      value: 1,
      text: '一个月(30天)',
    },
    {
      value: 6,
      text: '半年(180天)',
    },
    {
      value: 12,
      text: '一年(365天)',
    },
  ]
}

export const loginUrl = 'https://new.loxcloud.com/login'

export function redirectToLogin() {
  window.location.href = loginUrl
}

export function notify(title, text, variant = 'info', icon = 'BellIcon') {
  Vue.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon,
      text,
      variant,
    },
  })
}

export function throwError(error) {
  return error
}

export function throwRes(res) {
  return res
}
